<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="180" contain src="@/assets/packages.png"></v-img>
      </v-card-text>
      <v-card-title>Acquisti</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          Ordini in lavorazione
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{ ordersLength }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/purchases' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetProvider",
  data() {
    return {
      loading: false,
      error: false,
      ordersLength: '',
    };
  },
  
  mounted: function () {
    this.loadPurchaseList();
  },

  methods: {
    loadPurchaseList() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/purchases", {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          
          let purchasesOpen = [];
          for (let purchase of response.data.purchases) {
            if (!purchase.closed) {
              purchasesOpen.push(purchase);
            }
          }
          //console.log(purchasesOpen)
          this.ordersLength = purchasesOpen.length;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
            this.errorText = err.response.statusText;
            this.snackbarColor = "red";
            this.snackbar = true;
          } else if (err.request) {
            this.errorText = "Errore in caricamento acquisti";
            this.snackbarColor = "red";
            this.snackbar = true;
          } else {
            console.log(err);
          }
          this.loading = false;
          this.error = false;
        });
    },
  },
};
</script>
