<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="180" contain src="@/assets/myrooms.png"></v-img>
      </v-card-text>
      <v-card-title>Le mie stanze</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          Stanze a te assegnate
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{ roomsLength }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/myrooms' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetRooms",
  data() {
    return {
      rooms: [],
      loading: false,
      error: false,
      roomsLength: '?',
    };
  },
  watch: {
    // rooms: function (val) {
    //   // console.log('rooms changed')
    //   // this.roomsLength = val.length;
    //   // console.log(this.roomsLength)
    // },
  },
  mounted: function () {
    this.loadRooms();
  },

  methods: {
    loadRooms() {
      this.loading = true;
      let component = this;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/rooms",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.rooms = response.data;
          this.loading = false;
          this.error = false;
          this.roomsLength = this.rooms.length;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          // console.log(err.response.data);
          this.roomsLength = '?';
          setTimeout(component.loadRooms, 1000);
        });
    },
    filterRooms(current) {
      //return rooms
      try {
        let filtered = [];
        for (let room of this.rooms) {
          if (room.ENDDATE === null) {
            if (current) {
              filtered.push(JSON.parse(JSON.stringify(room)));
            }
          } else {
            if (!current) {
              filtered.push(JSON.parse(JSON.stringify(room)));
            }
          }
        }
        return filtered;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
  },
};
</script>
