<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="180" contain src="@/assets/myassets.png"></v-img>
      </v-card-text>
      <v-card-title>I miei beni</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          Beni a te assegnati
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{
              assetsLength
            }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/myassets' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "WidgetAssets",
  data() {
    return {
      assets: [],
      loading: false,
      error: false,
      assetsLength: 0,
    };
  },
  methods: {
    loadAssets() {
      this.loading = true;
      let component = this;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/assets",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.assets = response.data;
          this.assetsLength = this.assets.length;
          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          console.log(this.errorText);
          this.assetsLength = '?';
          setTimeout(component.loadAssets, 1000);
        });
    },
  },
  mounted: function () {
    this.loadAssets();
  },
  computed: {
    filterAssets() {
      try {
        let filtered = [];
        for (let asset of this.assets) {
          if (asset.DT_BUONO_SCARICO) {
            continue;
          }
          if (asset.UserAsset.ENDDATE === null) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        }

        return filtered;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
  },
};
</script>
