<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="206" contain src="@/assets/tandt.png"></v-img>
      </v-card-text>
      <v-card-title>Avvisi</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          Invia una comunicazione
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/texting' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetMsg",
  data() {
    return {
      rooms: [],
      loading: false,
      error: false,
      roomsLength: '?',
    };
  },
  watch: {
    // rooms: function (val) {
    //   // console.log('rooms changed')
    //   // this.roomsLength = val.length;
    //   // console.log(this.roomsLength)
    // },
  },
  mounted: function () {
    this.loadRooms();
  },

  methods: {
    loadRooms() {
      this.loading = true;
      let component = this;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/rooms",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.rooms = response.data;
          this.loading = false;
          this.error = false;
          this.roomsLength = this.rooms.length;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          // console.log(err.response.data);
          this.roomsLength = '?';
          setTimeout(component.loadRooms, 1000);
        });
    },
    filterRooms(current) {
      //return rooms
      try {
        let filtered = [];
        for (let room of this.rooms) {
          if (room.ENDDATE === null) {
            if (current) {
              filtered.push(JSON.parse(JSON.stringify(room)));
            }
          } else {
            if (!current) {
              filtered.push(JSON.parse(JSON.stringify(room)));
            }
          }
        }
        return filtered;
      } catch (err) {
        console.log(err);
        return [];
      }
    },
  },
};
</script>
