<template>
  <div>
    <v-card elevation="8" max-width="300">
      <v-card-text>
        <v-img height="180" contain src="@/assets/mypurchases.png"></v-img>
      </v-card-text>
      <v-card-title>I miei acquisti</v-card-title>

      <v-card-text>
        <v-row align="center" class="mx-0">
          Acquisti
          <span>
            <v-chip v-if="error" class="ma-2">?</v-chip>
            <v-chip v-else-if="!loading" class="ma-2">{{
              purchases.length
            }}</v-chip>
            <v-chip v-else class="ma-2"
              ><v-progress-circular
                indeterminate
                size="8"
                width="2"
              ></v-progress-circular
            ></v-chip>
          </span>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col class="text-right">
          <v-btn
            color="primary"
            class="ma-2"
            @click="$router.push({ path: '/mypurchases' })"
          >
            Dettagli
          </v-btn></v-col
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "WidgetPurchases",
  data() {
    return {
      purchases: [],
      loading: false,
      error: false,
    };
  },
  mounted: function () {
    // let rooms= [];
    this.loadPurchases();
  },

  methods: {
    loadPurchases() {
      this.loading = true;
      let component = this;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/users/" +
            this.$store.state.userMatricola +
            "/purchases",
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.purchases = response.data;
          this.loading = false;
          this.error = false;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          setTimeout(component.loadPurchases, 1000);
        });
    },
  },
};
</script>
