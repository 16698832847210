<template>
<div>

  <v-card elevation=8  max-width="300" >
<v-card-text>
    <v-img
      height="92"
      contain
      src="@/assets/cloudsync.png"
    ></v-img>
</v-card-text>
    <v-card-title>Ultime sincronizzazioni</v-card-title>

    <v-card-text>
      <v-row v-for="item in entity" align="center" class="mx-0" :key="item.name" dense>
      <v-col align=left ><span :style="{ 'color' : [item.running ? 'orange' : '' ]}">{{ assetsIT[item.name] }}: {{ dateFormat(item.lastsync) }}</span>
      </v-col>

      </v-row>
    </v-card-text>

    <v-card-actions>
          <v-col class="text-right">
      <v-btn color="primary" class="ma-2" @click="$router.push({path: '/sync'})">
        Dettagli
      </v-btn></v-col>
    </v-card-actions>
  </v-card>

  </div>
</template>

<script>
const moment = require("moment-timezone");


export default {
  name: "WidgetDbsync",
  data() {
    return {

      assetsIT: {
        assets: 'Beni',
        rooms: 'Stanze',
        users: 'Persone',
        groups: 'Gruppi',
    },

    entity: [],

    }
  },

  created: function () {
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/sync", {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.syncStatus = response.data;

          for (let oneentity of response.data.entities) {
            
            this.entity.push(oneentity);


          }

          
          this.loading = false;
        })
        .catch((err) => {
          if (err.response) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          console.log(err);
        });
   },
  methods: {
    dateFormat(dateString) {
      return moment(dateString)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, HH:mm:00");
    },


 
  }

}
</script>
